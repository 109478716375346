import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import SinglePost from './components/SinglePost'
import Post from './components/Post'
import Project from './components/Project'
import NavBar from './components/NavBar'
import NotFound from './components/NotFound'
import Timeline from './components/timeline'



import '../node_modules/p5/lib/p5.js'
import './styles/App.scss'
import './styles/styles.css'
import 'react-bootstrap'

function App({ history }) {
  console.log('Starting App')
  return (
    <BrowserRouter history={history}>
      <NavBar />
      <Switch>
        <Route component={Home} path="/" exact />
        <Route component={About} path="/about" />
        <Route component={SinglePost} path="/post/:slug" />
        <Route component={Post} path="/fabrication" />
        <Route component={Project} path="/project" />
        <Route component={Timeline} path="/timeline" />
        
        <Route path="*" component={NotFound} status={404} />
      </Switch>
    </BrowserRouter>
  )
}

export default App


// function refreshPage() {
//   window.location.reload()
// }
