import React from "react"
import Sketchy2 from "./P5Wrapper/sketch1/sketchy2.js";

export default function Home () {

    return (
        <div className="homebody z-0 ">
            <section>
                <div className="homepageformat w-screen">
                    <div className="container galleryblock relative justify-center items-center">
                        
                    </div>
                    <footer className="footer">
                        <div className="">
                        
                            <center>
                            Chris Parsell
                                <br/>Oakland, CA
                                <br/>    &copy; {new Date().getFullYear()} 
                            </center>
                        
                        </div>
                    </footer>
                </div>
                    <Sketchy2 />
            </section>
        </div>
    )
}

