import React, { useEffect, useState } from "react"
import { useParams, Link } from 'react-router-dom'
import sanityClient from '../client.js'
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
    return builder.image(source)
}

export default function SinglePost() {
    const [singlePost, setSinglePost] = useState(null)
    const { slug } = useParams()
    
    useEffect (() => {
        sanityClient.fetch(`*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }             
            },
            body,
        }
        `).then((data) => setSinglePost(data[0]))
        .catch(console.error)
    },[slug])

    if (!singlePost) return <div>Loading...</div>

    return (
        <main>
            <article className="container imgformat">
                <div className=" w-full h-full relative justify-center align-items-center">
                    <img 
                        className=" singleImg object-cover w-full rounded" 
                        src={urlFor(singlePost.mainImage.asset.url).url()}
                        alt={singlePost.title}
                        />
                    <div className="under">
                        <header className=" ">
                            <div className="relative">
                                <Link to="/fabrication" className="imgback my-2">
                                    <div className=" ">
                                        <h3 className="">Back</h3>
                                    </div>
                                </Link>
                                <div className="rounded py-2 px-1">
                                    <h2 className=" ">{singlePost.title}</h2>
                                    <p className="text-2xl"></p> 
                                </div>
                                
                            </div>
                            
                        </header>
                        <div className="px-1 prose lg:prose-xl max-w-full">
                            <BlockContent blocks={singlePost.body} projectId="y10cp6wr" />
                        </div>
                    </div>
                </div>
            </article>
        </main>

    )
}