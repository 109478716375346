import React from "react"
import sanityClient from "../client.js"

export default function NotFound() {
    return (
        <div className="pageformat">
            <div className="container ">
            
            <h1 className=" ">404</h1>
            <p></p>
            <p></p> <h1>You're fired. You didn't follow proto.</h1>
            </div>
        </div>
    )
    
}