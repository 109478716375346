import React, { Component, useState, useEffect } from 'react'
// import ReactDOM from 'react-dom'
import sanityClient from '../client.js';
import BlockContent from '@sanity/block-content-to-react';
//import { HoverCard, IExpandingCardProps } from "office-ui-fabric-react/lib/HoverCard";
//import { BaseComponent } from "office-ui-fabric-react/lib/Utilities";
//import Hovercard from "hovercard";
// import ReactHover, { Trigger, Hover } from "react-hover";
import '../styles/tooltip.css';

export default function Timeline() {
    const [timelineData, setTimeline] = useState(null)
    useEffect(() => {
        sanityClient
        .fetch(
            `*[_type== "timeline"] | order(dateTime(date) desc)  {
                        date,
                        title,
                        blockTitle,
                        description,
                        
                    }`
        )
        .then((data) => setTimeline(data))
        .catch(console.error)
    }, [])
    
    const optionsCursorTrueWithMargin = {
        followCursor: true,
        shiftX: 10,
        shiftY: -114
    };

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const monthsFull = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return (  
        <div className="">  
            
            <div class="container tw-z-0">
            <div class=" ">
                <TimeCalc />  
            </div>
                <table class="table-responsive">

                    <thead class="">
                        <th><h1 class="text-xl text-gray-600 mb-6 mt-20 ">History</h1></th>
                    </thead>
                    <tbody>
                        {timelineData &&
                            timelineData.map((timelinePost, index) => (
                                <tr class="">
                                    <div class="wrapper history">
                                        <td class="">
                                            <h2 class="text-gray-600">
                                                {months[new Date(timelinePost.date).getMonth()]} {new Date(timelinePost.date).getFullYear()}
                                            </h2>
                                        </td>
                                            <td class="px-4 py-1 bg-opacity-75 timedesc w-screen">
                                                <ul class="container ">
                                                    <li class="" id="eventDescription">
                                                        <strong>
                                                            <BlockContent blocks={timelinePost.blockTitle} projectId="y10cp6wr" dataset="project"/>
                                                        </strong>
                                                    </li>
                                                    <li className="text-gray-800">
                                                        <p>
                                                            {timelinePost.title}
                                                        </p>
                                                        <div class="tooltip ">
                                                            <h2 class="text-gray-600">
                                                                {monthsFull[new Date(timelinePost.date).getMonth()]} {new Date(timelinePost.date).getDate()} {new Date(timelinePost.date).getFullYear()}
                                                            </h2>
                                                            <strong>
                                                                <BlockContent blocks={timelinePost.blockTitle} projectId="y10cp6wr" dataset="project"/>
                                                            </strong>
                                                            <p>
                                                                <BlockContent blocks={timelinePost.description} projectId="y10cp6wr" dataset="project" className=""/>
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                                
                                            </td>
                                            
                                    </div>
                                </tr>
                                
                        
                    ))}
                    </tbody>
                </table>
            </div>
        </div> 
    )
}
function fullAge (currentdate) {
    let bday = new Date("Sep 27, 1984 22:15:00");
    let month_diff = currentdate - bday.getTime();
    let age_dt = new Date(month_diff);
    let age = ""
    let yr = age_dt.getYear()-70 + " years, ";
    let mo = age_dt.getMonth()
    if (mo === 1) mo = mo + " month, ";
    if (mo === 0) mo = "";
    if (mo > 1) mo = mo + " months, "
    let day = age_dt.getDay()
    if (day === 1) day = day + " day, ";
    if (day === 0) day = "";
    if (day > 1) day = day + " days, "
    let hr = age_dt.getHours()
    if (hr === 1) hr = hr + " hour, ";
    if (hr === 0) hr = "";
    if (hr > 1) hr = hr + " hours, "
    let min = age_dt.getMinutes()
    if (min === 1) min = min + " minute, ";
    if (min === 0) min = "";
    if (min > 1) min = min + " minutes, "
    let sec = age_dt.getSeconds()
    if (sec === 1) sec = sec + " second, ";
    if (sec === 0) sec = sec + " seconds.";
    if (sec > 1) sec = sec + " seconds. ";
    age = yr + mo + day + hr + min + sec;
    return age
}
export class TimeCalc extends Component {
// export function TimeCalc () {

    state = {
        date: new Date()
    }
    // const [setDate, theDate] = useState(new Date())
    // const [setAge, myAge] = useState (null)
    componentDidMount() {
        setInterval(
            () => {
                let newDate = new Date()
                this.setState({date: newDate, myAge: fullAge(newDate) })
            }, 1000
        );
    }
    // useEffect (() => {
    //     const timer = setInterval (() => {
    //         setDate(new Date())
    //         fullAge(theDate)
    //     }, 1000)
        
    //     // const myAge = setInterval(fullAge(theDate), 1000)
    // }, [])

    render () {
    let bday = new Date("Sep 27, 1984 22:15:00");
    let month_diff = this.state.date - bday.getTime();
    let age_dt = new Date(month_diff);
    let year = age_dt.getUTCFullYear();
    let currentYrs = Math.abs(year-1970);
    
    let currentDays = Math.floor(month_diff / (1000 * 60 * 60 * 24)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //let hours = Math.floor((month_diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //let minutes = Math.floor((month_diff % (1000 * 60 * 60)) / (1000 * 60));
    //let seconds = Math.floor((month_diff % (1000 * 60)) / 1000);
    let totalHours = Math.floor(month_diff / (1000 * 60 * 60)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let totalMins = Math.floor(month_diff / (1000 * 60)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let totalSecs = Math.floor(month_diff / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // let myAge = fullAge();
    // this.setState({myAge: fullAge() })

    return (
        <div class="container mt-16 timecalc">
            <td class="timetable w-screen">
                <ul class=" p-3 ">
            <div class="row">
                <div class="col">
                    <h5 class="text-gray-600">Accumulation of time on this planet in this timeline</h5>
                    <div class="divide-solid"></div>
                </div>
            </div>
            <div className="row">
                <div className="col text-gray-500">
                    <p>
                        {this.state.myAge}
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p></p>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col text-gray-500">
                    <p><b>Years on Earth</b></p>
                    <h2 class="text-gray-800">{currentYrs}</h2>
                </div>
                <div class="col text-gray-500">
                    <p><b>Days on Earth</b></p>
                    <h2 class="text-gray-800">{currentDays}</h2>
                </div>
                <div class="col text-gray-500">
                    <p><b>Hours on Earth</b></p>
                    <b><h2 class="text-gray-800">{totalHours}</h2></b>
                </div>
                <div class="col text-gray-500">
                    <p><b>Minutes on Earth</b></p>
                    <b><h2 class="text-gray-800">{totalMins}</h2></b>
                </div>
                <div class="col text-gray-500">
                    <p><b>Seconds on Earth</b></p>
                    <b><h2 class="text-gray-800">{totalSecs}</h2></b>
                </div>
            </div>
            
            </ul>
            </td>
        </div> 
    )
    }
}