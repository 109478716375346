import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import image from "../image/insta.png";
import '../styles/App.scss'

export default class NavBar extends Component {
  
  render() {
      let activeMode = { 
        color: '#172427',
        border: '2px solid rgb(0, 0, 0)',
        borderRadius: '2px',
        padding: '6px',
        transition: 'all 0.5s ease 0s'
      };
      let activeHome = { 
        color: '#172427',
        border: '2px solid rgb(0, 0, 0)',
        borderRadius: '2px',
        paddingLeft: '9px',
        paddingRight: '9px',
        paddingTop: '5px',
        paddingBottom: '5px',
        transition: 'all 0.5s ease 0s'
        //marginLeft: '-4px',
        
      };

    return (
      <section className="navbar-expand-sm navbar-light flex-row">
        <div className="">
          <nav className="fixed-top ">
            <div className="flex  ">
              <div className="mt-6 ml-3">
                <ul className="navbar-logo  ">
                  <li className="">
                    <NavLink to="/" exact className="" activeStyle={activeHome}>
                      Chris Parsell
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="collapse navbar-collapse d-flex">
                <ul className="navbar-nav  ">
                  {/* <li scope=" ">
                     <NavLink
                      to="post"
                      className=" col px-8"
                      activeStyle={activeMode}
                    >
                      Gallery
                    </NavLink>
                  </li> */}
                  {/*
                  <li scope="  ">
                    <NavLink to="project" className=" col px-18" activeStyle={activeMode}>
                      Projects
                    </NavLink>
                  </li>*/}
                  <li>
                  <NavLink to="/fabrication" className=" justify-center" activeStyle={activeMode}>
                            Fabrication
                        </NavLink>
                  </li>
                  <li>
                    <NavLink to="about" className=" col px-18" activeStyle={activeMode}>
                      About
                    </NavLink> 
                  </li>
                </ul>
                <div className="social">
                  {/* <SocialIcon url="https://www.instagram.com/cparsell/" style={{ height: 50, width: 50, marginTop: '20px', marginRight: '80px' }} /> */}
                  <a href="https://www.instagram.com/cparsell/" target="_blank" rel="noreferrer" >
                    <img src={image} alt="instagram" />
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
    )
  }
}
//console.log('NAVBAR console log');

// function RenderApp() {
//   ReactDOM.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     document.getElementById('root')
//   )
// }
