import React from "react"
import Timeline from '../components/timeline'

export default function About() {
    return (
        <div className="pageformat">
            <div className="container about">

            <h2 className=" ">Tinkerer, Maker</h2>
            <p></p> 
            <p>Coming from the Los Angeles suburbs, I learned to design and to make in Woodbury University's School of Architecture. After graduating from there, I continued to pass on what I learned to other students. I've continued to focus on digital fabrication as a means for design innovation. I believe that understanding how things are made imparts critical thinking about technology and its emergent complexities.</p>
            <p>I currently work at Jacobs Institute for Design Innovation, where I teach people how to use 3D printers, CNC machines, and manage day-to-day operations of professional-grade equipment. I've worked with engineers, artists, architects, and researchers with the goal of understanding their design concept and helping them develop it and make it real.</p>
            </div>
            <div className="timeline offset-md-2">
                <Timeline />
            </div>
        </div>
    )
}