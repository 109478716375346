import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import sanityClient from '../client.js'
//import '../typography.min.css'
//import '../styles.css'

import '../styles/App.scss'

export default function Post() {
  const [postData, setPost] = useState(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type== "post"] | order(publishedAt desc){
                    title,
                    slug,
                    mainImage{
                        asset->{
                            _id, 
                            url
                        }, 
                        alt
                    }
                }`
      )
      .then((data) => setPost(data))
      .catch(console.error)
  }, [])
  return (
    <body id="post">
      <main className="pageformat min-h-screen p-14">
        <section className="container">
          <h1 className="justify-center "></h1>
          

          <div className="imggrid grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {postData &&
              postData.map((post, index) => (
                <article>
                  <Link
                    to={'/post/' + post.slug.current}
                    key={post.slug.current}
                  >
                    <div
                      className="block post-image relative rounded shadow lg:shadow-lg md:shadow-md leading-snug bg-white border-2 border-gray-100"
                      key={index}
                    >
                      <img
                        src={post.mainImage.asset.url}
                        alt={post.mainImage.alt}
                        className="w-full h-full rounded-r object-cover absolute "
                      />
                      {/* <span className="block relative h-full flex justify-left items-end pr-5 pb-4 "> */}
                      {/* <span className="tooltipa">
                        <h3 className="text-gray-800 text-lg  px-3  bg-blue-200 text-gray-700 bg-opacity-75 rounded">
                          {post.title}
                        </h3>
                      </span> */}
                    </div>
                  </Link>
                </article>
              ))}
          </div>
        </section>
      </main>
    </body>
  )
}
