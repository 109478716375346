import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import sanityClient from '../client.js';

import '../styles/App.scss';

export default function Project() {
    const [projectData, setProjectData] = useState(null)

    useEffect(() => {
        sanityClient
          .fetch(
              `*[_type== "project"]{
                  title,
                  date,
                  place,
                  description,
                  projectType,
                  link,
                  tags
              }`
            )
            .then((data) => setProjectData(data))
            .catch(console.error)
    }, [])
    return (
        <body id="project">
        <main className="pageformat">
        <section className="container mx-auto">
            
            <h1 className="flex ">Projects</h1>
            <h2 className=" text-gray-300 flex  mb-12"></h2>
            <p>Projects</p>
            <section className="grid grid-cols-2 gap-8">
                {projectData && 
                projectData.map((project, index) => (
                <article className="relative rounded-lg shadow-xl bg-white">
                    <h3 className="text-gray-800 font-bold mb-1 hover:text-red-700">
                        <Link className="text-gray-800 font-bold mb-2 ml-2 mt-2 hover:text-red-700"
                        to={project.link}
                        alt={project.title}
                        target="_blank" 
                        rel="noopener noreferrer"

                        >
                            {project.title}
                        </Link>
                    </h3>
                    <div className="text-gray-500 text-xs space-x-4"> 
                        <span className="ml-3"><strong className="font-bold"></strong>
                        {new Date(project.date).toLocaleDateString()}
                        </span>
                        
                        <span>
                            <strong className="font-bold">Type</strong>:
                            {project.projecttype}
                        </span>

                        <p className="my-4 text-lg text-gray-700 leading-relaxed">
                            {project.description}
                        </p>
                        <a 
                        href={project.link} rel="noopener noreferrer" target="_blank"
                        className="text-red-500 font-bold hover:no-underline hover:text-red-400"
                        >View Project </a>
                        <span role="img" >→</span>
                    </div>
                </article>
                ))}
            </section> 
            
        </section>
        </main>
    </body>
    )
    
}