import {getWidth} from "./Width";
import {getHeight} from "./Width";
import React from "react"
import p5 from 'p5';

//import './src/color/p5.Color.js';

export default class Sketchy2 extends React.Component {
    constructor(props) {
      super(props)
      this.myRef = React.createRef()
    }

    Sketch = ( p ) => {
        var ppHeight;
        var ppWidth;
        var divX,divY;
        var gridX, gridY;
        var decay = 0;
        var addDecay = true;
        var decayToAdd = 1;
        var decayT = 0;
        var starting=200;
        
        //let clr1 = p.color('#04ADBF'); // teal
        //let clr2 = p.color('#025959'); // greenish
        //let clr3 = p.color('#F2E0C9'); // tan brown
        //let clr4 = p.color('#A0A603'); // yellow green
        //let clr5 = p.color('#04BFBF'); // teal
      
        //let clr1 = p.color(99,39,112, 170);//purple
        //let clr2 = p.color(212,106,106, 170); //let clr3 = p.color('#D46A6A');
        //let clr3 = p.color('#320138');
        //let clr4 = p.color(15,129,150,160); //turqoise
        //let clr5 = p.color(231,174,78);
        let clr2 = p.color('#383838');//green dark
        let clr1 = p.color('#81F573'); //green light
        let clr4 = p.color('#F57567');
        let clr5 = p.color('#36A4A8'); //turqoise
        let clr3 = p.color('#5BEFF5');
        let t = 0;
        let t2 = 10;
        
        p.setup = function () {
            p.updateBrowserSize();
            p.createCanvas(ppWidth, ppHeight);
            divX = 10;  //grid size X
            divY = 6;  //grid divisions Y
            var x;
            var y;
        };

        p.mousePressed = function () {
            if(addDecay ===false) addDecay = true;
            decayToAdd += 90;
            if (decayT < 1) decayT = 2;
        }

        p.updateBrowserSize = function () {
            ppHeight = getHeight();
            ppWidth = getWidth();
            divX = p.abs(ppWidth/130);
            divY = p.abs(ppHeight/140);
        }
    
        p.draw = function () {
            //p.background(0,0,0,255);
            p.updateBrowserSize();
            gridX = ppWidth/divX;   //number of pixels in X
            gridY = ppHeight/divY;
            p.resizeCanvas(ppWidth, ppHeight);
            
            let mouseXX = p.map(p.mouseX,0,ppWidth,ppWidth/2-ppWidth/5,ppWidth/2+ppWidth/5);
            let mouseYY = p.map(p.mouseY,0,ppHeight,ppHeight/2-ppHeight/5,ppHeight/2+ppHeight/5);
            mouseXX = p.map(mouseXX, ppWidth/2-ppWidth/5,ppWidth/2+ppWidth/5, ppWidth/2+ppWidth/5, ppWidth/2-ppWidth/5);
            mouseYY = p.map(mouseYY,ppHeight/2-ppHeight/5,ppHeight/2+ppHeight/5,ppHeight/2+ppHeight/5,ppHeight/2-ppHeight/5);
            var x; var y;
            for (let i=-5; i<= divX+3; i++) { 
                for (let j=-8; j<= divY+2; j++) { 
                    if(ppWidth<760) { //if cell phone-sized, adjust grid
                         x = i*gridX-450 + decayT;//+gridX*.5;
                         y = j*gridY-10;// + gridY*.5;
                    }else {
                         x = i*gridX+100;//+gridX*.5;
                         y = j*gridY+100 + decayT;// + gridY*.5;
                    }
                    let noisex = p.map(p.noise(i+t/4), 0, 1, (gridX-gridX*2)/4, gridX/4);
                    let noisey = p.map(p.noise(j+t/4), 0, 1, (gridY-gridY*2)/4, gridY/4);
                    let oscDia = p.sin(t*2+i+p.cos(i+j*2) + decayT);
                    let oscDia2 = p.sin(t*5+i+p.cos(i+j*3) + decayT);
                    let oscDia3 = p.sin(t*2+i+p.cos(i+j*2) + decayT);
                    
                    let oscMap = p.map(oscDia, -1, 1, 10, gridX, true);
                    // let oscMap2 = p.map(oscDia2, -1, 1, 10, gridX, true);
                    // let oscMap3 = p.map(oscDia3, -1, 1, 10, gridX, true);
                    
                    //oscillation, distance from mouse, mapping oscillation to grid size, and remapping it
                    let distOsc = p.dist(mouseXX+400-oscMap+decay/2, mouseYY+100-oscMap+decay/2, x, y);
                    let distFromMouse = p.dist(mouseXX+decay/2, mouseYY+decay/2, x, y);
                    let distOscMap = p.map(distOsc, 0, ppWidth, 1, gridX*1.5, true);
                    let dia22 = p.map(distOscMap+decay/2, 1, gridX*1.5,(gridX*2)-(gridX*4),gridX*3)+100;

                    let diaRev = p.map(distFromMouse, 0, ppWidth, gridX*1.5, 1, false);
                    let dia22rev = p.map(diaRev, 1, gridX*1.5,(gridX*2)-(gridX*4),gridX*3);
                    
                    //x+=dia2*2-dia2;
                    //y+=dia2*2-dia2;
                    x+=distOscMap*2.1-dia22rev/4+noisex;
                    y+=distOscMap*2.1-dia22rev/4+noisey;

                    //p.fill(i*(gridX/2),j*(gridY/2),100,170);
                    //p.ellipse(x, y,3,3);
                    let lerpo=i*divX+j;
                    let lerpMap = p.map(lerpo,-1,divX*divX+divY,0,1);
                    let newClr = p.lerpColor(clr1,clr1,lerpMap);
                    let newClr2 = p.lerpColor(clr5,clr1,lerpMap);
                    let newClr3 = p.lerpColor(clr3,clr4,lerpMap);
                    newClr.setAlpha(110-dia22*1+(decay/2));
                    newClr2.setAlpha(490-dia22*1.5-starting);
                    clr4.setAlpha(110-dia22*1-starting);
                    
                    clr1.setAlpha(40-dia22+distOscMap-starting);
                    clr3.setAlpha(100-dia22/3-starting);
                    clr5.setAlpha(120-dia22/3-starting);
                    
                    newClr3.setAlpha(490-dia22*1.5-starting);
                    p.fill(clr1);
                    p.stroke(clr5);
                    
                    p.strokeWeight(p.map(oscDia2+decay/10,-1,1,15,3));
                    //let dia2 = distFromMouse * (gridX / p.width);
                    
                    //ellipse 
                    p.ellipse(x,y,oscMap+distOscMap/2+noisex+noisey,oscMap+distOscMap/2+noisex+noisey);

                    clr2.setAlpha(p.map(230-dia22/2-distOscMap/2,0,255,-20,200)-starting);
                    clr3.setAlpha(p.map(320-dia22/2-distOscMap/2,0,255,0,50)-starting);
                    
                    p.stroke(clr2);
                    p.fill(clr3);
                    
                    p.strokeWeight(p.map(oscDia3,-1,1,1,8));

                    //ellipse
                    p.ellipse(x, y, oscDia2+dia22, oscDia2+dia22);

                    //bX = (i)*gridX;//+gridX*.5;
                    //bY = (j+1)*gridY;// + gridY*.5;
                    let aX = x + p.cos(t+i+j)*distOscMap*.2;  //X coordinate of point rotating around the circumference of the circles
                    let aY = y + p.sin(t+i+j)*distOscMap*.2;
                    let bX = x + p.cos(t2+i+j)*distOscMap*.5;  //X coordinate of point rotating around the circumference of the circles
                    let bY = y + p.sin(t2+i+j)*distOscMap*.5;
                    let boscDia = p.map(p.sin(t2*2+(i)+p.cos((i)+(j+1)*2)),0,1,1,12, false);
                   
                    newClr2.setAlpha(50+dia22/2-starting);
                    clr4.setAlpha(100+dia22/3-starting);
                    
                    //newClr.setAlpha(p.map(120-dia22,0,255,30,180));
                    p.stroke(newClr2);
                    p.strokeWeight(p.map(p.sin(t*2+i+j+p.cos(i*2))+decay/20,-1,1,.5,3));
                    p.fill(clr4);

                    //ellipses
                    p.ellipse(aX,aY,boscDia,boscDia);
                    p.ellipse(bX,bY,boscDia,boscDia);
                    newClr3.setAlpha(10+dia22/4-(starting*1.4));
                    p.stroke(newClr3);
                    p.strokeWeight(2.5);

                    //lines
                    p.line(bX,bY, aX,aY);
                    p.line(aX,aY, x,y);
                    p.line(bX,bY, x,y);
                }
            }

            t+=0.008;
            t2+=0.002;
            if (addDecay==true) {

                //decay = p.exp(decayT);
                decayT += 1;
                decay = p.pow(1+.8, decayT);
                if (decay > decayToAdd) {
                    addDecay = false;
                    decayToAdd = 0;
                    decayT = 0;
                }
            } 
            if (addDecay === false) {
                decay = decay * p.pow(1-.03, 2);
                //if (decay<0) decay = .1;
            }
            if (starting > 80){
                starting -=.2;
            }
        };
    };

    componentDidMount() {
        this.myP5 = new p5(this.Sketch, this.myRef.current)
      }

      componentWillUnmount() {
        this.myP5.remove();
      }

      render() {
        return (
          <div ref={this.myRef} />
        )
      }
}
